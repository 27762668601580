<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div class="textcenter"  v-if="isSpinner && isSuspendAction">
      <h2>Please wait for the process to complete.</h2>
    </div>
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <b-card class="user_detail_top_section current_detail_top_section">
        <b-row v-for="(item, index) in rowsArrayData" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Cancellation Date</h5>
            <p>{{ TemplateCreatedDate(item.sessionCansallationTime).split(",")[0] }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Status</h5>
            <p class="badge text-capitalize badge-light-warning badge-pill" v-if="item.status === 3">Pending</p>
          </b-col>
          <b-col xl="6" lg="3" md="3">
                <h5>Cancellation Reason</h5>
                <div
                      class="wrap-break-word"
                      v-show="true"
                      >
                      <span v-html="limitTo(item.cansalReason,220)"></span>
                      <a
                          class="readmore cancelReadMore"
                          v-b-modal="'readmore' + item.Id"
                          v-show="item.cansalReason.length >= 220"
                          
                          >Read more</a
                      >
                      <UserModal title="Cancel Reason" :text="item.cansalReason" :id="item.Id" />
                  </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="user_detail_top_section current_detail_top_section">
        <h4>{{bookingTitle}}</h4>
        <b-row v-for="(item, index) in rowsArrayData" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingId }}</p>
            <h5>Category</h5>
            <p>
              <router-link
                :to="{ name: 'category-details', params: { id: item.categoryId}}"
                class="text-decoration-underline"
                >{{ item.category }}</router-link
              >
            </p>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.tutorAvtar" class="user_img" />
              <router-link
              :to="{ name: 'user-details', params: { id: item.bookingTutor}}"
                class="text-decoration-underline"
                >{{ item.tutorNameDetail }}</router-link
              >
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>
              {{sessionTimeCalculate(item.sessionBooking, item.sessionEnd)}}
            </p>
             <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :style="getTutorTypeStyle(item.tutorType)">
                <!-- :variant="getTutorTypeBadgeVariant(item.tutorType)" -->
                {{ item.tutorType }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ TemplateCreatedDate(item.booked) }}</p>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.learnerAvtar" class="user_img" />
              <router-link
              :to="`/user-management/users/${item.bookinglernerId}`"
                class="text-decoration-underline"
                >{{ item.learnerChildName }}</router-link
              >
            </span>
             <h5 style="margin-top:10px;">Search Tag(s)</h5>
            <div class="tag_btn">
              <div v-for="(item, index) in rowsArrayData" :key="index">
                <b-badge
                  v-for="(items, index1) in item.tagName"
                  :key="index1"
                  class="btn mr-2 mb-1 btn-outline-secondary"
                >
                  {{ item.tagName.length !==0 ?items:'N/A' }}
                </b-badge>
              </div>
            </div>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <p class="booking_credits" v-if="item.bookingcredits !== 0" style="color: #28c76f !important;font-weight:500;">{{ item.bookingcredits }} Credits</p>
            <p style="color:red;" v-else>Trial Booking</p>
          </b-col>
          <b-col md="6" class="mt-2">
            <h5>Description</h5>
            <span v-html="limitTo(item.description,200)"></span>
                <a
                    class="description readmore cancelReadMore"
                    v-b-modal="'readmore' + item.bookingId"
                    v-show="item.description.length >= 200"
                    
                    >Read more</a
                >
            <UserModal title="Cancel Reason" :text="item.description" :id="String(item.bookingId)" />
          </b-col>
          <b-col md="6" class="mt-2">
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { mapGetters , mapActions } from "vuex";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/CanceledSession(s)/cancelSessionRequestDetail.vue"
import router from '@/router';
import moment from "moment";
export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      currentitems: [],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      bookingIdForUpdate: "",
      userId:'',
      rowsArrayData: [],
      isSuspendAction: false,
      bookingTitle:"",
    };
  },
  created() {
    let self = this;
    this.$root.$on("suspendWaitProcessMessage", () => {
      this.isSuspendAction = true;
    });
    this.$root.$on("suspendWaitProcessMessageOff", () => {
      this.isSuspendAction = false;
    });
    self.$root.$on("stopSpinnerForActionPerformed", () => {
      self.isSpinner = false;
    });
    self.$root.$on('startSpinnerForActionPerformed', () => {
      self.isSpinner = true;
    });
      this.isSpinner = true
     this.cancelDetailPageUp()
  },
  beforeRouteLeave(from,to,next){
   var self = this;
     let  name = "";
     let data = '';
     let datas = ''
     self.$root.$emit('cancelSessionRequestDetailsForName', name,data,datas);
     self.$root.$emit('cancelSessionRequestTutorStatus', name,data);
     next()
  },
  computed: {
    ...mapGetters({
      getCancelSessionList: "cancelSessionDetails/getCancelSession",
      getsettingList : 'settings/getsettingArrayFeild',
      gettutorTypesList: "settings/getTutorTypes",
    }),
  },
  methods: {
    ...mapActions({
      getCancelSessionDatas: "cancelSessionDetails/getCancelSessionData"
    }),
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    cancelDetailPageUp() {
      var self = this;
      try {
        self.isSpinner = true
        self.$root.$emit('startButtonDisabled')
        this.rowsArrayData = [];
        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.BOOKING,this.$route.params.id,(cancelDatas)=>{
        if(cancelDatas.status === false){
          self.$root.$emit('showToastMessage','Record not found.','danger');
          self.$router.push({ name: "cancellation-session-details" });
          self.isSpinner = false;
          return;
        }
        let cancelData = cancelDatas.data
        this.userId = cancelData.tutor,
            getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.SESSIONS,cancelData.sessionDetails.id,(ress)=>{
              let res = ress.data
              self.$root.$emit('cancelSessionRequestDetailsForName', cancelData.bookingId,cancelData.tutor,cancelData.id);
              this.bookingTitle = cancelData.bookingsTitle ? cancelData.bookingsTitle : "";
              var object = {
                bookingId: cancelData.bookingId,
                Id: cancelData.Id,
                statuss: cancelData.cancelReasonStatus,
                learnerChildName:
                  (cancelData.learnerDetails
                    ?cancelData.learnerDetails
                    .firstName:'') +
                  " " +
                  (cancelData.learnerDetails
                    ?cancelData.learnerDetails
                    .lastName:''),
                tutorNameDetail:
                 (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .firstName:'') +
                  " " +
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .lastName:''),
                sessionCansallationTime: cancelData.cancellationDate ? cancelData.cancellationDate :'',
                bookingcredits:
                  cancelData.usedCredits?cancelData.usedCredits:0,
                cansalReason: cancelData.cancellationReason,
                sessionBooking:
                  cancelData.startTime,
                sessionEnd: cancelData.endTime,
                status: cancelData.status,
                booked: cancelData.createdAt,
                tutorType:
                  cancelData.tutorDetails
                    ? cancelData.tutorDetails
                    .tutorType:'',
                category:
                  cancelData.categoryDetails
                    .categoryName,
                categoryId:
                  cancelData.categoryDetails.id,
                sessionId:
                  cancelData.sessionDetails.id,
                teachinglanguage: res.teachingLanguageDetails ? res.teachingLanguageDetails.title : '-',
                description: res.description ? res.description :'',
                tagName: res.tags,
                bookingTutor: cancelData.tutor,
                bookinglernerId:
                  cancelData.learnerId,
                learnerAvtar: cancelData.learnerDetails.profileImage ? cancelData.learnerDetails.profileImage :"",
                tutorAvtar: cancelData.tutorDetails.profilePicture ? cancelData.tutorDetails.profilePicture :"",
              };
              this.rowsArrayData.push(object);
              self.isSpinner = false;
              self.$root.$emit('stopButtonDisabled')
            }
          );
      })
      } catch (error) {
        console.error("error", error);
        self.isSpinner = false;
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "cancelDetailPageUp",
            errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
        setTimeout(() => {
          this.$root.$emit('stopButtonDisabled');
        }, 300);
      }
    },
    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var cancelSessionPageDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var cancelSessionPageMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var cancelSessionPageYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returncancelSessionPageDate =
        self.addZero(cancelSessionPageDate) +
        "-" +
        self.addZero(cancelSessionPageMonth) +
        "-" +
        cancelSessionPageYear +
        ", " +
        self.cancelCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );

      return returncancelSessionPageDate;
    },
    TemplateEndDate(TemplateEndDate) {
      var self = this;
      var returnCancelDate = self.cancelCreateTime(
        new Date(TemplateEndDate.seconds * 1000)
      );
      return returnCancelDate;
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    cancelCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    }, 
    limitTo: function (value,lengthOfText) {
        if(value){
            if (value.length < lengthOfText){
                return value
            }else{

                return value.substring(0, lengthOfText) + "...";
            }
        }else{
            return "-";
        }
    },
    getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == "Normal")
      {
        variant += 'success';
      }
      else if(value == "Elite")
      {
        variant += 'danger';
      }
      else if(value == "Valuable")
      {
        variant += 'primary';
      }
      else{
        variant += 'warning'
      }
      return variant;
    },
  },
};
</script>
<style scope>
  .cancelReadMore{
    color: #0096DB !important;
    text-decoration: none !important;
  }
  .textcenter h2 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 200px;
  }
</style>